import { NgModule } from '@angular/core';

import { ContentTitleDirective } from '../content-title/content-title.directive';

import { TabComponent } from './tab/tab.component';
import { TabContentDirective } from './tab/tab-content.directive';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { SwipeableTabDirective } from './tab-group-swipable/swipeable-tab.directive';
import { TabGroupSwipeableComponent } from './tab-group-swipable/tab-group-swipeable.component';
import { TabLabelDirective } from './tab-label.directive';
import { TabPlainLinkDirective } from './tab-nav-bar/tab-plain-link.directive';
import { TabNavPrefixDirective } from './tab-nav-prefix.directive';
import { TabNavSuffixDirective } from './tab-nav-suffix.directive';

@NgModule({
  imports: [
    ContentTitleDirective,
    SwipeableTabDirective,
    TabComponent,
    TabContentDirective,
    TabGroupComponent,
    TabGroupSwipeableComponent,
    TabLabelDirective,
    TabNavPrefixDirective,
    TabNavSuffixDirective,
    TabPlainLinkDirective,
  ],
  exports: [
    SwipeableTabDirective,
    TabComponent,
    TabContentDirective,
    TabGroupComponent,
    TabGroupSwipeableComponent,
    TabLabelDirective,
    TabNavPrefixDirective,
    TabNavSuffixDirective,
    TabPlainLinkDirective,
  ],
})
export class TabsModule {}
