<nav role="tablist" [class.borderless]="borderless">
  <ng-content select="[uiTabNavPrefix]" />
  <ng-container *ngIf="tabs">
    <a
      *ngFor="let tab of tabs.toArray() | sortByOrder; trackBy: trackByTabId"
      role="tab"
      [id]="tab.tabId"
      [class.active]="tab.active"
      [class.disabled]="tab.disabled"
      [attr.aria-selected]="tab.active"
      (click)="selectTab(tab)"
    >
      <span uiContentTitle>
        <ng-template [ngIf]="tab.templateLabel">
          <ng-template [cdkPortalOutlet]="tab.templateLabel" />
        </ng-template>
        <ng-template [ngIf]="!tab.templateLabel">
          {{ tab.label }}
        </ng-template>
      </span>
    </a>
  </ng-container>
  <ng-content select="[uiTabNavSuffix]" />
</nav>

<ng-content select="ui-tab" />
