import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

/**
 * Simplifies the process of setting a title tooltip based on the content
 * useful to use alongside ellipsis text spans
 */
@Directive({
  selector: '[uiContentTitle]',
})
export class ContentTitleDirective {
  @HostBinding('title') title: string = '';

  constructor(private readonly _elementRef: ElementRef<HTMLElement>) {}

  @HostListener('mouseenter') onMouseenter(): void {
    this.title = this._elementRef.nativeElement?.textContent?.trim() ?? '';
  }
}
