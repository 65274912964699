import { Pipe, PipeTransform } from '@angular/core';

import { sortByOrder } from '../../functions/sort-by.order';
import { SortByOrderItem } from '../../types/sorts';

@Pipe({
  name: 'sortByOrder',
})
export class SortByOrderPipe implements PipeTransform {
  transform<TValue extends SortByOrderItem>(values: TValue[]): TValue[] {
    return sortByOrder(values);
  }
}
