import { PortalModule } from '@angular/cdk/portal';
import { NgForOf, NgIf } from '@angular/common';
import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  TrackByFunction,
} from '@angular/core';
import { head } from 'lodash-es';

import { sortByOrder } from '@stsm/shared/functions/sort-by.order';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { SortByOrderPipe } from '@stsm/shared/pipes/sort-by-order/sort-by-order.pipe';

import { ContentTitleDirective } from '../../content-title/content-title.directive';
import { TabComponent } from '../tab';

@Component({
  selector: 'ui-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  imports: [NgIf, SortByOrderPipe, NgForOf, PortalModule, ContentTitleDirective],
  // Default to automatically detect content changes, would otherwise need manual markForCheck in parent component
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TabGroupComponent<T> implements AfterContentInit {
  @ContentChildren(TabComponent) tabs?: QueryList<TabComponent<T>>;

  @HostBinding('class.borderless')
  @Input({ transform: booleanAttribute })
  borderless: boolean = false;

  /**
   * Emits after a tab was changed
   */
  @Output() readonly tabChange: EventEmitter<TabComponent<T>> = new EventEmitter<TabComponent<T>>();

  constructor(private readonly _loggerService: LoggerService) {}

  readonly trackByTabId: TrackByFunction<TabComponent<T>> = (index: number, tab: TabComponent<T>) => tab.tabId ?? index;

  ngAfterContentInit(): void {
    // wait for external tab inits
    setTimeout((): void => {
      const activeTabs: TabComponent<T>[] = this.tabs?.filter((tab: TabComponent<T>): boolean => tab.active) ?? [];

      if (activeTabs.length === 0) {
        const tabs: TabComponent<T>[] = this.tabs?.toArray() ?? [];
        const firstTab: TabComponent<T> | undefined = head(sortByOrder(tabs));

        if (firstTab) {
          this._loggerService.debug(`Selecting the first tab "${firstTab.tabId}"...`);
          this.selectTab(firstTab);
        }
      }
    });
  }

  /**
   * Selects a new tav and deactivates all others
   *
   * @param newTab tab to be selected
   */
  selectTab(newTab: TabComponent<T> | undefined): void {
    this._loggerService.debug(`selectTab()`);

    if (!newTab || newTab.disabled) {
      return;
    }

    this._loggerService.debug(`Selecting the tab "${newTab.tabId}"...`);

    if (this.tabs) {
      for (const tab of this.tabs) {
        tab.active = false;
      }
    }

    newTab.active = true;
    this.tabChange.emit(newTab);
  }
}
