import { PortalModule } from '@angular/cdk/portal';
import { NgIf } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, HostBinding, Input } from '@angular/core';

import { SortByOrderItem } from '@stsm/shared/types/sorts';

import { TabLabelDirective } from '../tab-label.directive';

import { TabContentDirective } from './tab-content.directive';

@Component({
  selector: 'ui-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  imports: [NgIf, PortalModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent<T> implements SortByOrderItem {
  @HostBinding('class.hidden') hidden: boolean = true;
  /**
   * Optional unique identifier to make it easier to find tabs
   */
  @HostBinding('attr.tab-id')
  @Input()
  tabId: T | undefined;

  /**
   * Sets a simple string label for complex labels, e.g. with icons, please use {@link TabLabelDirective}
   */
  @Input() label: string = '';

  /**
   * If true sets the current tab as active tab, only one tab can be active at a time
   */
  @Input({ transform: booleanAttribute })
  set active(active: boolean) {
    this._active = active;
    this.hidden = !this._active;

    if (this._active) {
      this.hasLoadedBefore = true;
    }
    // required to update content inside ng-container *ngIf
    this._changeDetectorRef.markForCheck();
  }

  get active(): boolean {
    return this._active;
  }

  @Input({ transform: booleanAttribute }) disabled: boolean = false;

  /**
   * @description
   * Specify at which position the tab should be visible.
   * Undefined values will be added to the end.
   * @default undefined
   */
  @Input() sortingOrder: number | undefined = undefined;

  @ContentChild(TabLabelDirective)
  set templateLabel(templateLabel: TabLabelDirective) {
    this._templateLabel = templateLabel;

    this._changeDetectorRef.markForCheck();
  }

  get templateLabel(): TabLabelDirective | undefined {
    return this._templateLabel;
  }

  @ContentChild(TabContentDirective, { static: true })
  set lazyContent(content: TabContentDirective) {
    this._lazyContent = content;

    this._changeDetectorRef.markForCheck();
  }

  get lazyContent(): TabContentDirective | undefined {
    return this._lazyContent;
  }

  hasLoadedBefore: boolean = false;

  private _active: boolean = false;
  private _templateLabel?: TabLabelDirective;
  private _lazyContent?: TabContentDirective;

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {}
}
